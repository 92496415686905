<template>
  <div class="user-agreement">
    <div class="container-fluid">
      <nav-title
        title="返回"
        conton="用户使用协议"
        :isBack="false"
        @navBackIncident="navBackIncident"
      >
      </nav-title>
      <pdf
        :src="src"
        :page="currentPage"
        @num-pages="pageCount = $event"
        @page-loaded="currentPage = $event"
        @loaded="loadPdfHandler"
      >
      </pdf>
      <div class="flex conet j-c">
        <div
          :class="['bg', currentPage == itme ? 'active' : '']"
          v-for="(itme, index) in pageCount"
          :key="index"
          @click="changePdfPage(itme)"
        >
          {{ itme }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NavTitle from '@/components/nav-title/index';
import { adminProtocolCurrent } from '@/api/api';
import pdf from 'vue-pdf';
export default {
  name: 'agreement',
  components: {
    NavTitle,
    pdf
  },
  data() {
    return {
      src: '',
      currentPage: 0, // pdf文件页码
      pageCount: 0, // pdf文件总页数
      fileType: 'pdf' // 文件类型
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const _this = this;
      const { type } = _this.$route.query;
      adminProtocolCurrent({ protocolType: type }).then((res) => {
        if (res.code == 200) {
          _this.src = pdf.createLoadingTask(res.data);
        }
      });
    },
    navBackIncident() {
      const _this = this;
      const { path } = _this.$route.query;
      _this.$router.replace({
        path: path,
        query: {
          path: _this.$route.path
        }
      });
    },
    changePdfPage(val) {
      this.currentPage = val;
    },
    loadPdfHandler(e) {
      this.currentPage = 1;
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.user-agreement {
  box-sizing: border-box;
  .bg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: @TEXT-BG-F4F4F4;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
    color: @TEXT-COLOR-888888;
    &.active {
      background: @TEXT-BG-03C99C;
      color: @TEXT-COLOR-FFFFFF;
    }
  }
  .bg:hover {
    background: @TEXT-BG-03C99C;
    color: @TEXT-COLOR-FFFFFF;
  }
  .conet {
    margin-bottom: 160px;
  }
}
</style>